<script>
import { watch } from '@vue/composition-api';
import {
  useStore,
  useGoHome,
  useContent,
  useGoQuickQS,
} from '@/utils/hooks';
import { Box } from '../components';
import { createState } from './MyAccount';
import lang from './MyAccount.json';
import css from './MyAccount.scss';

export default {
  setup(props, { emit }) {
    const store = useStore();
    const content = useContent(lang);
    const x = createState();

    watch([() => store.state.userInfo, content], ([v1, v2]) => {
      if (!v1) return;
      x.commit('userInfo', v1);
      x.commit('content', v2);
    }, { immediate: true });

    const goQuickQS = useGoQuickQS(emit);

    const event = e => {
      const o = {
        moreDevices: goQuickQS,
      };
      o[e] && o[e]();
    };

    return {
      content,
      x,
      event,
      goBuy: useGoHome(),
    };
  },
  render() {
    const {
      x,
      content,
      event,
      goBuy,
    } = this;
    const level = this.$store.state.isSvip
      ? x.getters.svipCountdownLevel
      : x.getters.countdownLevel;
    return (
      <Box list={x.getters.list} onEvent={event}>
        <div class={css.account}>
          <h6>{content.title1}</h6>
          <div class={css.email}>
            <span>{x.getters.email}</span>
            {(x.getters.verified
              ? <img src={`${this.$imgSrcBase}/imgs/pers_center/vaild.png`} />
              : <button onClick={() => this.$router.push('/center/5')}>{content.verify}</button>
            )}
          </div>
          <div class={css.registered}>{x.getters.registered}</div>
        </div>
        <div class={css.time}>
          <h6>{content.title2}</h6>
          {x.getters.isExpired
            ? (
              <div class={css.expired}>
                <img src={`${this.$imgSrcBase}/imgs/pers_center/expired.png`} />
                <p>{content.expiredTips}</p>
                <button onClick={goBuy}>{content.recharge}</button>
              </div>
            )
            : (
              <div
                class={[css['no-expired'], css[level]]}
              >
                <div>{x.getters.expired}</div>
                <div>{x.getters.countdownStr}</div>
                {this.$store.state.isSvip && <div>SVIP {x.getters.svipExpired}</div>}
                {this.$store.state.isSvip && <div>SVIP {x.getters.svipCountdownStr}</div>}
                <button onClick={goBuy}>{content.recharge}</button>
              </div>
            )
          }
          <div class={css['link-list']}>
            <ul>
              <li onClick={() => this.$router.push('/center/2')}>{content.paymentHistory}</li>
              {this.$store.state.isAgent || this.$store.state.isQuickQS || (
                <li onClick={() => this.$router.push('/center/4')}>{content.referFriends}</li>
              )}
            </ul>
          </div>
        </div>
      </Box>
    );
  },
};
</script>
